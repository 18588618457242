<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card
              no-body
              class="p-4"
            >
              <b-card-body>
                <b-form
                  novalidate
                  @submit.prevent="onSubmit"
                >
                  <h1>Забыли пароль?</h1>
                  <p class="text-muted">
                    Сброс пароля
                  </p>
                  <b-form-group
                    id="usernameInputGroup1"
                    label="Email пользователя"
                    label-for="user_string"
                  >
                    <b-form-input
                      id="user_string"
                      v-model.trim="$v.form.user_string.$model"
                      type="text"
                      name="user_string"
                      :state="chkState('user_string')"
                      aria-describedby="input1LiveFeedback1"
                      placeholder="Email пользователя"
                      autocomplete="user_string, email"
                      autofocus
                    />
                    <b-form-invalid-feedback
                      id="input1LiveFeedback1"
                    >
                      Это поле обязательно для заполнения
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        type="submit"
                        variant="primary"
                        :disabled="$v.form.$invalid || loading"
                        :class="{'loading' : loading}"
                      >
                        Сбросить пароль
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {forgotPassword} from '../services/api';
import notifications from '../components/mixins/notifications';

const formShape = {
  user_string: '',
};

export default {
  name: 'ForgotPassword',
  mixins: [validationMixin, notifications],
  data() {
    return {
      form: Object.assign({}, formShape),
      loading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      user_string: {
        required,
      },
    },
  },
  methods: {
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await forgotPassword(this.form);
        if (response && response.status === 200) {
          this.showSuccess('Вам отправленно письмо для сброса пароля');
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
